import { defineStore } from 'pinia';

export const useSearchStore = defineStore('search', {
    state: () => ({
        activeTab: '',
        isCruiseport: false,
        destination: {
            airport_code: '',
            fullname: '',
            location: { latitude: '', longitude: '' },
            type: '',
            terminal: '',
        },
        package: { id: '', name: '', additional: '' },
        dates: { start: '', end: '' },
        guests: { guests: 2, rooms: 1 },
        package_options: {
            PSF: {
                id: 'PSF',
                name: 'At the beginning of my trip',
                additional: 'the night before my flight',
                start_date: {
                    title: 'Hotel Check-In Date',
                    tooltip: 'When will you first arrive at the hotel?',
                },
                end_date: {
                    title: 'Parking Return Date',
                    tooltip: 'When will you pick up your vehicle?',
                },
            },
            PFS: {
                id: 'PFS',
                name: 'At the end of my trip',
                additional: 'the night when I get back from my trip',
                start_date: {
                    title: 'Parking Start Date',
                    tooltip: 'When will you first arrive at the hotel?',
                },
                end_date: {
                    title: 'Hotel Sleep Date',
                    tooltip: 'When will you return at the hotel?',
                },
            },
            PSFS: {
                id: 'PSFS',
                name: 'Both at the beginning & end of my trip',
                additional: 'the night before my flight & the night I get back',
                start_date: {
                    title: 'First Night Check-In',
                    tooltip: 'When will you first arrive at the hotel?',
                },
                end_date: {
                    title: 'Return Night Check-In',
                    tooltip: 'When will you return at the hotel?',
                },
            },
        },
        promo: '',
    }),
    actions: {
        updateActiveTab(tab: string) {
            this.activeTab = tab;
        },
        updateIsCruisePort(isCruiseport: boolean) {
            this.isCruiseport = isCruiseport;
        },
        update(params: any) {
            this.destination = params.destination ?? this.destination;
            this.package = params.package ?? this.package;
            this.dates = params.dates ?? this.dates;
            this.guests = params.guests ?? this.guests;
        },
        updatePromo(promo: string) {
            this.promo = promo;
        },
        updateRooms(rooms: any) {
            this.guests.rooms = rooms;
        },
    },
    persist: true,
});

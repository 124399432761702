import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        id: null,
        role: null,
        csrf: null,
    }),
    actions: {
        update(params: any) {
            if (params) {
                this.id = params?.login?.id;
                this.role = params?.login?.role;
                this.csrf = params?.csrf;
            } else {
                console.error('Unable to get "auth" store params');
            }
        },
    },
});

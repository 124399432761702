<template>
    <form id="sbHotelForm" class="form" @submit.prevent="submitForm()">
        <LazyUiSearchboxComponentsDestination
            :name="isCruiseport ? 'cruiseport' : 'airport'"
            :title="destinationTitle"
            :tooltip="destinationTooltip"
            :placeholder="destinationPlaceholder"
            :value="formData.destination"
            :validation="v$.destination"
            @update:value="(value: any) => updateDestination(value)"
        />
        <LazyUiSearchboxComponentsDates
            :package-info="option"
            :min-date="new Date()"
            :value="formData.dates"
            :validation="v$.dates"
            :allow-single-range="true"
            :allow-same-date="true"
            :date-range="true"
            :apr="apr"
            @update:value="(value: any) => updateDates(value)"
        />
        <AdvancedBtn
            v-if="apr"
            component-variant="primary"
            component-type="button"
            button-type="submit"
            :with-arrow="true"
            :is-loading="loading"
        >
            Find your space
        </AdvancedBtn>
        <div v-else class="input-group-container submit">
            <span class="title">Submit</span>
            <button type="submit">
                <template v-if="!loading">Search <span class="fullname">Parking</span></template>
                <NuxtIcon v-show="loading" name="ri:refresh-line" class="animate-spin loading-icon" />
            </button>
        </div>
    </form>
</template>

<script setup lang="ts">
import type { ISearchboxTab } from '~ui/types';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { useSearchStore } from '../../../../../apps/_base/store/search';
import type { ISearchTrip } from '~ui/types/components/Searchbox';
import AdvancedBtn from '~ui/components/AdvancedBtn.vue';

const { tabs, loading } = defineProps<{
    tabs: ISearchboxTab;
    loading: boolean;
    apr?: boolean;
    allowSameDate?: boolean;
}>();
const emit = defineEmits<{
    (e: 'submitSearch', query: ISearchTrip): void;
}>();

// Constant variable for access to search store
const searchStore = useSearchStore();

const destinationTitle = computed(() => {
    if (tabs.departureLabel && tabs.cruiseportDepartureLabel) {
        return isCruiseport.value ? tabs.cruiseportDepartureLabel : tabs.departureLabel;
    } else {
        return `Departure ${isCruiseport.value ? 'Cruise Port' : 'Airport'}`;
    }
});

const destinationTooltip = computed(() => {
    if (tabs.departureToolTip && tabs.cruiseportDepartureToolTip) {
        return isCruiseport.value ? tabs.cruiseportDepartureToolTip : tabs.departureToolTip;
    } else {
        return `Which ${isCruiseport.value ? 'harbour' : 'airport'} are you departing from?`;
    }
});

const destinationPlaceholder = computed(() => {
    if (tabs.departurePlaceholder && tabs.cruiseportDeparturePlaceholder) {
        return isCruiseport.value ? tabs.cruiseportDeparturePlaceholder : tabs.departurePlaceholder;
    } else {
        return `Enter ${isCruiseport.value ? 'Cruise Port' : 'Airport'}`;
    }
});

const isCruiseport = computed(() => formData.value?.destination?.type === 'cruiseport' || searchStore.isCruiseport);

const formData: any = ref({
    destination: searchStore.destination,
    dates: searchStore.dates,
});

const option = computed(() => {
    return {
        id: 'APR',
        name: 'At the parkinglot',
        additional: '',
        start_date: {
            title: tabs.startDateLabel || 'Parking Check-In',
            tooltip: tabs.startDateTooltip || 'Date when your parking begins',
            placeholder: tabs.startDatePlaceholder || 'Select Date',
        },
        end_date: {
            title: tabs.endDateLabel || 'Parking Check-Out',
            tooltip: tabs.endDateTooltip || 'Date when your parking ends',
            placeholder: tabs.endDatePlaceholder || 'Select Date',
        },
    };
});

const rules = computed(() => {
    return {
        destination: {
            fullname: {
                required: helpers.withMessage('Please enter destination', required),
                minLength: minLength(3),
            },
            required: helpers.withMessage('Please enter destination', required),
            minLength: minLength(3),
        },
        dates: {
            start: {
                required: helpers.withMessage('Select Date', required),
            },
            end: {
                required: helpers.withMessage('Select Date', required),
            },
        },
    };
});

const v$ = useVuelidate(rules, formData);

const updateDestination = (val: any) => {
    formData.value.destination = val;
    searchStore.update({ destination: val });
};

const updateDates = (val: any) => {
    formData.value.dates = val;
    searchStore.update({ dates: val });
};

//TODO: Are we use this function in this component?
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getCurrentDate() {
    let date = new Date();
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split('T')[0];
}

const submitForm = () => {
    v$.value.$validate();

    if (!v$.value.$error) {
        searchStore.update({ destination: formData.value.destination });
        searchStore.update({ dates: formData.value.dates });

        const destination: any = searchStore.destination;

        const query = {
            airport: destination.airport_code,
            destination,
            package: 'APR',
            date1: new Date(formData.value.dates?.start).toLocaleString('en-CA', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            }),
            date2: new Date(formData.value.dates?.end).toLocaleString('en-CA', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            }),
            guests: 2,
            rooms: 1,
        };

        // Example JSON
        // {
        //     "airport": "LAX",
        //     "destination": {
        //         "type": "airport",
        //         "fullname": "Los Angeles (LAX)",
        //         "location": {
        //             "latitude": "33.942536",
        //             "longitude": "-118.40807"
        //         },
        //         "airport_code": "LAX"
        //     },
        //     "date1": "Nov 14, 2023",
        //     "date2": "Nov 16, 2023",
        //     "guests": 2,
        //     "rooms": 1,
        //     "package": "APR"
        // }
        emit('submitSearch', query);
    }
};
</script>

<template>
    <form id="sbHotelForm" class="form" @submit.prevent="submitForm()">
        <LazyUiSearchboxComponentsDestination
            name="destinations"
            :title="tabs.destinationLabel || 'Where are you going?'"
            :tooltip="tabs.destinationTooltip || 'Which city, airport, landmark, or address do you want to stay near?'"
            :placeholder="tabs.destinationPlaceholder || `Enter city, airport, landmark or address`"
            :value="formData.destination"
            :validation="v$.destination"
            :only-airports="false"
            @update:value="(value: any) => updateDestination(value)"
        />
        <LazyUiSearchboxComponentsDates
            :package-info="option"
            :min-date="new Date()"
            :value="formData.dates"
            :validation="v$.dates"
            :allow-single-range="true"
            :allow-same-date="false"
            :date-range="true"
            @update:value="(value: any) => updateDates(value)"
        />
        <LazyUiSearchboxComponentsGuests
            name="guests"
            :title="tabs.guestLabel || 'Guests'"
            :tooltip="tabs.guestTooltip || 'How many rooms and guests?'"
            :value="formData.guests"
            @update:value="(value: any) => updateGuestsAndRooms(value)"
        />
        <div class="input-group-container submit">
            <span class="title">Submit</span>
            <button type="submit">
                <template v-if="!loading">Search <span class="fullname">Hotels</span></template>
                <NuxtIcon v-show="loading" name="ri:refresh-line" class="animate-spin loading-icon" />
            </button>
        </div>
    </form>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { useSearchStore } from '../../../../../apps/_base/store/search';
import type { ISearchboxTab } from '~ui/types';
import type { ISearchTrip } from '~ui/types/components/Searchbox';

const props = defineProps<{
    tabs: ISearchboxTab;
    loading: boolean;
    allowSameDate?: boolean;
}>();
const emit = defineEmits<{
    (e: 'submitSearch', query: ISearchTrip): void;
}>();

// Constant variable for access to search store
const searchStore = useSearchStore();

const option = computed(() => {
    return {
        id: 'HRO',
        name: 'At the hotel',
        additional: '',
        start_date: {
            title: props.tabs.startDateLabel || 'Check-In',
            tooltip: props.tabs.startDateTooltip || 'Hotel Check-In Date',
            placeholder: props.tabs.startDatePlaceholder || 'Select Date',
        },
        end_date: {
            title: props.tabs.endDateLabel || 'Check-Out',
            tooltip: props.tabs.endDateTooltip || 'Hotel Check-Out Date',
            placeholder: props.tabs.endDatePlaceholder || 'Select Date',
        },
    };
});

const formData = ref({
    destination: searchStore.destination,
    dates: searchStore.dates,
    guests: searchStore.guests,
});

const updateDestination = (val: any) => {
    formData.value.destination = val;
    searchStore.update({ destination: val });
};

const updateDates = (val: any) => {
    formData.value.dates = val;
};

const updateGuestsAndRooms = (val: any) => {
    formData.value.guests = val;
};

const isMax28DaysApart = (value) => {
    const startDate = new Date(formData.value.dates.start);
    const endDate = new Date(value);
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays < 28;
};

const checkEndDate = () => {
    return new Date(formData.value.dates.start).toDateString() != new Date(formData.value.dates.end).toDateString();
};

const rules = computed(() => {
    return {
        destination: {
            fullname: {
                required: helpers.withMessage('Please enter destination', required),
                minLength: minLength(3),
            },
            required: helpers.withMessage('Please enter destination', required),
            minLength: minLength(3),
        },
        dates: {
            start: {
                required: helpers.withMessage('Select Date', required),
            },
            end: {
                required: helpers.withMessage('Select Date', required),
                maxDate: helpers.withMessage('Reservations cannot be longer than 28 nights.', isMax28DaysApart),
                checkEndDate: helpers.withMessage(
                    `${option.value?.end_date?.title} must be after ${option.value?.start_date?.title} `,
                    checkEndDate
                ),
            },
        },
        guests: {
            required: helpers.withMessage('Please select number of guests & rooms', required),
        },
    };
});

const v$ = useVuelidate(rules, formData);
//TODO: Are we use this function in this component?
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getCurrentDate() {
    let date = new Date();
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split('T')[0];
}

const submitForm = () => {
    v$.value.$validate();

    if (!v$.value.$error) {
        searchStore.update({ destination: formData.value.destination });
        searchStore.update({ dates: formData.value.dates });
        searchStore.update({ guests: formData.value.guests });

        const destination: any = searchStore.destination;

        const query = {
            airport: destination.airport_code,
            destination,
            package: 'HRO',
            date1: new Date(formData.value.dates.start).toLocaleString('en-CA', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            }),
            date2: new Date(formData.value.dates.end).toLocaleString('en-CA', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            }),
            guests: formData.value.guests.guests,
            rooms: formData.value.guests.rooms,
        };

        emit('submitSearch', query);
    }
};
</script>

<template>
    <div class="input-group-container has-value">
        <label :for="`form-input-${name}`" class="input-group-title has-icon">{{ title }}</label>
        <div class="input-group" :class="{ error: validation ? validation.$error : false }">
            <div v-if="prependIcon" class="input-icon-prepend">
                <NuxtIcon :name="`ri:${prependIcon}`" />
            </div>
            <div v-if="appendIcon" class="input-icon-append">
                <NuxtIcon :name="`ri:${appendIcon}`" />
            </div>
            <select
                :id="`form-input-${name}`"
                :name="name"
                :value="value"
                :data-tooltip="tooltip"
                :class="[!value.length && defaultOption ? 'text-gray-500' : '']"
                @change="(event) => emit('update:value', (event.target as HTMLInputElement).value)"
            >
                <option v-if="defaultOption" disabled :selected="!value.length" value="">{{ defaultOption }}</option>
                <option v-for="option in options" :key="option.value" :value="option.value" :disabled="option.disabled">
                    {{ option.label }}
                </option>
            </select>
            <div v-if="tooltip" class="tooltip">{{ tooltip }}</div>
            <div v-if="validation && validation.$error && validation.required.$invalid" class="error-tooltip">
                {{ validation.required.$message }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
defineProps({
    name: { type: String, required: true, default: 'Sleep' },
    title: { type: String, required: true, default: 'Title' },
    tooltip: { type: String, required: false },
    options: { type: Object, required: true, default: () => ({}) },
    defaultOption: { type: String, required: false },
    value: { type: String, required: false, default: '' },
    prependIcon: { type: String, required: false, default: '' },
    appendIcon: { type: String, required: false, default: 'arrow-down-s-line' },
    validation: { type: Object, required: false, default: null },
});

// Constant variable for initialising emit events
const emit = defineEmits<{
    (e: 'update:value', value: string): void;
}>();
</script>

<style lang="postcss" scoped>
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
}
</style>

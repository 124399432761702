<template>
    <UiSearchboxMain
        id="apr_searchbox"
        :blok="blok"
        :loading="loading"
        :allow-same-date="false"
        :apr="true"
        :titles="[]"
        @submit-storyblok="submitSearch"
    />
</template>

<script setup lang="ts">
interface ISearchboxConfig {
    Tabs: any[];
    _uid: string;
    _editable: string;
    DefaultTab: string;
    DefaultCruiseport: boolean;
    component: string;
    [key: string]: any;
}

defineProps<{
    blok: ISearchboxConfig;
}>();
const config = useRuntimeConfig();
const loading = ref(false);
const submitSearch = async (body, sf: false) => {
    loading.value = true;
    if (sf) {
        await $fetch(config?.public.SEARCH_SF_URL as string, {
            method: 'POST',
            body,
            onResponse({ response }) {
                if (response.ok) {
                    loading.value = false;
                    const redirectUrl = response._data.data.redirect_url;
                    navigateTo(redirectUrl, { external: true });
                }
            },
            onResponseError({ response }) {
                loading.value = false;
                console.error(response._data.message);
                console.error(response._data.errors);
            },
        }).catch(() => {
            loading.value = false;
        });
    } else {
        const query = {
            ...body,
            checkindate: body.date1,
            checkoutdate: body.date2,
        };

        const isAprPackage = body.package === 'APR';
        const searchUrl = isAprPackage ? 'search' : 'psf_search/airport';

        if (!isAprPackage) {
            delete query.destination;
        }

        await $publicApi(searchUrl, {
            method: 'POST',
            body: query,
            onResponse({ response }) {
                if (response.ok) {
                    loading.value = false;
                    let redirectUrl = response._data.redirect;
                    // Remove Absolute Link
                    redirectUrl = redirectUrl.replace(config?.public.BOOKING_URL, '');
                    navigateTo(redirectUrl, { external: true });
                }
            },
            onResponseError({ response }) {
                loading.value = false;
                console.error(response._data.message);
                console.error(response._data.errors);
            },
        });
    }
};
</script>

<style lang="postcss">
#apr_searchbox {
    @apply max-w-full md:max-w-[725px] xl:max-w-full bg-white md:bg-white px-3 pt-3 pb-2 sm:px-2 sm:pt-4 rounded-lg;

    .calendar-container .vc-pane-container .calendar-header {
        @apply hidden;
    }

    .tabs {
        @apply mb-4 bg-transparent px-0 justify-center sm:justify-start gap-2 sm:gap-0;
        .tab {
            @apply sm:px-2 flex-auto sm:flex-grow-0;
            .title {
                @apply h-11 sm:h-12 px-1 py-2 sm:px-4 sm:py-3 bg-surface-brand-light text-text-primary-asphalt rounded-lg border-none text-xs;
                .icons {
                    @apply flex mr-0 sm:mr-1;
                    span {
                        @apply inline-block leading-4;
                    }
                }
                span {
                    @apply text-base font-semibold;
                }
            }
            .subtitle {
                @apply bg-transparent text-sm border-none;
            }

            &.active {
                @apply border-transparent;
                .title {
                    @apply bg-brand-primary-500 rounded-lg text-white;
                }
                .iconify {
                    @apply text-white bg-white;
                }
            }
        }
        .subtitle {
            @apply hidden;
        }
    }

    .mobile-titles {
        @apply hidden;
    }

    .form {
        @apply md:px-1 p-0 md:py-2 items-end;
        .airport .dropdown-list {
            @apply xl:min-w-[500px];
        }
        .dates {
            @apply flex-col sm:flex-row;
        }
        .guests {
            input {
                @apply p-0 text-base text-ellipsis;
            }
        }
        .dates-container {
            @apply w-full;
            .bar {
                @apply hidden;
            }
        }
        .input-group-container {
            @apply px-2 relative;
            .input-group-title {
                @apply block md:block sm:mb-2 md:mb-1 text-sm text-text-primary-asphalt static;
            }

            .input-icon-append {
                @apply md:top-3;
            }

            .input-group {
                input,
                select {
                    @apply text-base rounded-lg sm:border border-solid border-neutral-mid-grey pt-0 h-auto sm:h-12;
                    &::placeholder {
                        @apply text-gray-500 font-normal;
                    }
                }
                .iconify {
                    &.input-icon-append {
                        right: 0.75rem;
                    }
                    @apply text-text-primary-asphalt;
                }
                &:hover,
                &:focus,
                &:focus-within {
                    input,
                    select {
                        @apply border-brand-primary-500 outline outline-2 outline-brand-primary-500 outline-offset-0;
                    }
                    .iconify {
                        @apply text-text-primary-asphalt;
                    }
                }

                .tooltip {
                    @apply bg-brand-primary-500 top-[-2.75rem];
                }

                .tooltip-handle:hover + .tooltip {
                    &::before {
                        @apply bg-brand-primary-500 top-5;
                    }
                }
                &.error {
                    input,
                    select {
                        @apply border-status-error-red-100 outline-status-error-red-100 outline outline-2;
                    }

                    .error-tooltip {
                        @apply bg-status-error-red-100;
                        &::before {
                            @apply bg-status-error-red-100;
                        }
                    }
                }
            }

            &.submit {
                @apply min-w-[160px] w-auto;
                @media screen and (max-width: 768px) {
                    @apply mb-0;
                }
                span {
                    @apply mb-2 md:mb-1;
                    @media screen and (max-width: 768px) {
                        @apply uppercase text-base mb-0;
                    }
                }
                button {
                    @apply bg-[#e62571] md:hover:bg-[#cd185f] font-normal text-[1.3rem];
                    @media screen and (max-width: 768px) {
                        @apply uppercase text-base font-bold;
                    }
                }
            }

            .dropdown-list,
            .dropdown-select {
                @apply py-1 absolute left-0 sm:whitespace-nowrap top-[70px] sm:top-[52px] font-normal text-text-primary-asphalt z-50 rounded-lg bg-white shadow-aprLarge;
                min-width: 100%;
                cursor: default;
                border: 1px solid #e7e9ea;
                .dropdown-list-item {
                    @apply sm:whitespace-nowrap border-none px-4 py-2 overflow-hidden text-ellipsis hover:bg-surface-brand-light;
                    .iconify {
                        @apply text-text-primary-asphalt;
                    }
                    &.active {
                        @apply bg-surface-brand-super-light hover:bg-surface-brand-super-light text-text-primary-asphalt hover:text-text-primary-asphalt;
                    }
                }
            }
            &.guests {
                .dropdown-select {
                    @apply text-sm;
                    .dropdown-select-item-title {
                        @apply flex items-center;
                    }
                    .dropdown-select-item {
                        @apply py-1;
                    }
                    .dropdown-select-item-selectors {
                        .button {
                            .iconify {
                                @apply text-text-secondary-grey;
                                &:hover {
                                    @apply text-text-primary-asphalt;
                                }
                            }
                            &.disabled {
                                .iconify {
                                    @apply text-text-secondary-grey;
                                }
                            }
                        }
                    }
                }
                .input-group {
                    input {
                        @apply sm:px-4 sm:pl-4 sm:!pr-6;
                    }
                    .input-icon-append {
                        @apply h-6 w-6 inline-block text-brand-primary-500 top-6 sm:top-[0.813rem] text-text-primary-asphalt;
                    }
                }
            }
            &.sleep-option {
                @apply xl:!max-w-[17.5rem] xl:min-w-[17.5rem];
                /* .input-group-title {
                    @apply text-ellipsis text-nowrap overflow-hidden;
                } */
                .input-icon-prepend {
                    @apply h-6 w-6 inline-block text-brand-primary-500 top-7 md:top-2 ml-1 absolute text-text-primary-asphalt;
                }
                .input-icon-append {
                    @apply h-6 w-6 inline-block text-brand-primary-500 top-6 sm:top-[0.813rem] absolute text-text-primary-asphalt;
                }
                input#form-input-sleep {
                    @apply pl-11;
                }
            }
            &.airport {
                @apply xl:max-w-[350px];
                input {
                    @apply pl-0 h-auto sm:h-12 sm:px-4;
                }
            }
            @media screen and (max-width: 767px) {
                @apply mb-2 h-[4.25rem] p-0 !ml-0 !mr-0;
                .input-group-title {
                    @apply text-sm block text-text-secondary-grey top-[0.7rem] left-4 absolute;
                }
                .input-group {
                    input,
                    select {
                        @apply text-base rounded-lg sm:border border-solid border-neutral-mid-grey pt-0 h-auto sm:h-12;
                        @apply h-[4.25rem] w-full pt-[36px] pb-4 px-4;
                        @apply rounded-lg border border-solid border-neutral-mid-grey;
                        &::placeholder {
                            @apply text-gray-500 font-normal;
                        }
                    }
                }
                &.toggle-input {
                    background-color: transparent;
                    .input-group-title {
                        @apply relative text-text-primary-asphalt sm:text-white top-0 left-0 text-center mb-0;
                        font-size: 0.6875rem;
                        font-weight: 400;
                    }
                    .toggle {
                        height: 44px;
                    }
                }
            }
        }
        .btn-brand {
            @apply w-full sm:w-[calc(100%-16px)] sm:mx-2 xl:w-auto xl:ml-2 shrink-0 mb-1 mt-2 xl:mt-auto self-center;
            &.shuttle-btn {
                @apply xl:mb-3;
            }
        }
        .dates {
            .input-group input {
                @apply pl-11 sm:pl-10;
            }
            .input-icon-prepend {
                @apply mt-2 ml-1 md:ml-0 md:-mt-2;
            }
        }
        .airport {
            .input-icon-prepend .iconify {
                @apply hidden relative -top-2;
            }
        }
        .passengers-select {
            .input-icon-prepend {
                @apply absolute top-8 sm:top-3 left-4 sm:left-3;
                & ~ select,
                & ~ input {
                    @apply pl-11 pt-[30px] sm:pt-0;
                }
            }
            .input-icon-prepend {
                @apply h-6 w-6 inline-block text-brand-primary-500 text-text-primary-asphalt;
            }
        }
        .toggle-inner,
        .toggle-switch,
        .toggle-label {
            @apply h-12 sm:h-12;
        }
        .toggle-inner::before {
            @apply bg-brand-primary-500;
        }
    }

    .icons > .iconify {
        @apply relative;
    }

    .form .custom-label small {
        @apply text-text-primary-asphalt;
    }
}
</style>
